export const MisFeatureStatus = {
  Available: 'available',
  NotReleased: 'not_released',
  NotAvailable: 'not_available'
}

export type TMisFeatureStatus = InferValues<typeof MisFeatureStatus>

export type MisStatisticItemDto = {
  name: string
  isMedlock: boolean
  successRate: number | null
  doctorsOnlineAppointment: TMisFeatureStatus
  servicesOnlineAppointment: TMisFeatureStatus
  servicesPriceList: TMisFeatureStatus
  lpusCount?: number
}

export const getMisStatistics = () => $apiFetch<MisStatisticItemDto[]>('/appointment_rates/')
