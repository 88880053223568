<template>
  <div class="w-100 d-flex flex-column align-center">
    <component
      :is="headingTag"
      class="text-h4 text-center"
    >
      Интеграции с МИС
    </component>

    <div class="my-6 d-flex flex-wrap flex-column flex-md-row align-center justify-center gr-3 gc-10">
      <div class="d-flex align-center">
        <v-icon
          class="mr-2"
          size="24"
          icon="ui-icon-check-circle-filled"
          color="success"
        />
        Реализовано
      </div>

      <div class="d-flex align-center">
        <v-icon
          class="mr-2"
          size="24"
          icon="ui-icon-circle-close"
          color="ui-kit-icon-secondary"
        />
        Не реализовано в МедФлекс
      </div>

      <div class="d-flex align-center">
        <v-icon
          class="mr-2"
          size="24"
          icon="ui-icon-octagon-x"
          color="ui-kit-text-error"
        />
        Недоступно в МИС
      </div>
    </div>

    <v-table
      :id="headerId"
      class="mis-list-table mis-list-table_header bg-ui-kit-bg-gray-0"
      :style="headerStyle"
    >
      <colgroup>
        <col
          span="1"
          class="mis-list-table__col-name"
        />
        <col
          span="1"
          class="mis-list-table__col-percent"
        />
        <col
          span="3"
          class="mis-list-table__col-status"
        />
        <col
          v-if="isLpusCountVisible"
          span="1"
          class="mis-list-table__col-lpus"
        />
      </colgroup>

      <thead>
        <tr class="text-ui-kit-text-secondary">
          <th
            class="mis-list-table__col-name"
          />
          <th class="text-center text-caption text-md-body-2 py-md-2 font-weight-regular mis-list-table__col-percent">
            Успешных записей<br />за 30 дней
          </th>
          <th class="text-center text-caption text-md-body-2 py-md-2 font-weight-regular mis-list-table__col-status">
            <span class="text-no-wrap">Онлайн-запись</span> к врачам
          </th>
          <th class="text-center text-caption text-md-body-2 py-md-2 font-weight-regular mis-list-table__col-status">
            <span class="text-no-wrap">Онлайн-запись</span>
            на услуги
          </th>
          <th class="text-center text-caption text-md-body-2 py-md-2 font-weight-regular mis-list-table__col-status">
            Выгрузка <span class="text-no-wrap">прайс-листа</span>
          </th>
          <th
            v-if="isLpusCountVisible"
            class="text-center text-caption text-md-body-2 py-md-2 font-weight-regular mis-list-table__col-lpus"
          >
            Подключено клиник с МедФлекс
          </th>
        </tr>
      </thead>
    </v-table>

    <v-table
      :id="tableId"
      class="mis-list-table"
    >
      <colgroup>
        <col
          span="1"
          class="mis-list-table__col-name"
        />
        <col
          span="1"
          class="mis-list-table__col-percent"
        />
        <col
          span="3"
          class="mis-list-table__col-status"
        />
        <col
          v-if="isLpusCountVisible"
          span="1"
          class="mis-list-table__col-lpus"
        />
      </colgroup>

      <tbody>
        <tr
          v-for="(mis, index) in isFinite(limit) ? data!.slice(0, limit) : data"
          :key="index"
        >
          <td class="text-subtitle-1 text-left mis-list-table__col-name">
            <div class="my-2">
              <NuxtLink
                v-if="mis.isMedlock"
                target="_blank"
                class="text-primary text-decoration-none"
                :href="useRuntimeConfig().public.mlUrl"
              >
                {{ mis.name }}
              </NuxtLink>
              <template v-else>{{ mis.name }}</template>
            </div>
          </td>
          <td
            class="text-body-1 text-center mis-list-table__col-percent"
            :class="{
              'text-ui-kit-text-info': isNull(mis.successRate)
            }"
            v-text="formatSuccessRate(mis.successRate)"
          />
          <td class="text-center mis-list-table__col-status">
            <MisListTableStatusIcon
              :table-scroll-x="x"
              :status="mis.doctorsOnlineAppointment"
            />
          </td>
          <td class="text-center mis-list-table__col-status">
            <MisListTableStatusIcon
              :table-scroll-x="x"
              :status="mis.servicesOnlineAppointment"
            />
          </td>
          <td class="text-center mis-list-table__col-status">
            <MisListTableStatusIcon
              :table-scroll-x="x"
              :status="mis.servicesPriceList"
            />
          </td>
          <td
            v-if="isLpusCountVisible"
            class="text-center mis-list-table__col-lpus text-body-1"
            v-text="mis.lpusCount"
          />
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script setup lang="ts">
import { isNull } from 'lodash-es'
import { useLayout } from 'vuetify'
import {
  type MisStatisticItemDto
} from '~/api/misStatistics'
import { ASYNC_DATA_KEYS } from '~/constants'

type Props = {
  limit?: number
  stickyHeader?: boolean
  headingTag?: string
}

const props = withDefaults(defineProps<Props>(), {
  limit: Infinity,
  stickyHeader: false,
  headingTag: 'h1'
})

const { data } = useNuxtData<MisStatisticItemDto[]>(ASYNC_DATA_KEYS.misList)

if (!data) {
  throw createError({
    statusCode: 500
  })
}

const isLpusCountVisible = computed(() => !!data.value?.[0]?.lpusCount)

const tableId = useId()
const headerId = useId()
const isMounted = useMounted()

const { mainRect } = useLayout()

const { x } = process.client
  ? useScroll(computed(() => {
    return isMounted.value
      ? document.querySelector(`#${tableId} .v-table__wrapper`) as HTMLDivElement
      : null
  }))
  : {
      x: ref(0)
    }

watch(x, (value) => {
  const header = document.querySelector(`#${headerId} .v-table__wrapper`)

  if (!header) {
    return
  }

  header.scrollLeft = value
})

const headerStyle = computed(() => {
  if (!props.stickyHeader || !isMounted) {
    return { } as {}
  }

  return {
    position: 'sticky',
    top: toCssSize(mainRect.value.top),
    zIndex: '1'
  }
})

function formatSuccessRate(rate: number | null) {
  if (isNull(rate)) {
    return 'мало данных'
  }

  return `${rate}%`
}
</script>

<style scoped lang="scss">
.mis-list-table {
  --v-table-row-height: 64px;
  --v-table-header-height: 48px;

  &_header:deep(.v-table__wrapper) {
    overflow: hidden;
  }

  &__col-name {
    width: 220px;
    min-width: 220px;
  }

  &__col-percent {
    width: 173px;
    min-width: 173px;

    @media (min-width: $desktop-breakpoint) {
      width: 189px;
      min-width: 189px;
    }
  }

  &__col-status {
    width: 106px;
    min-width: 106px;

    @media (min-width: $desktop-breakpoint) {
      width: 140px;
      min-width: 140px;
    }
  }

  &__col-lpus {
    width: 133px;
    min-width: 133px;

    @media (min-width: $desktop-breakpoint) {
      width: 168px;
      min-width: 168px;
    }
  }

  thead th, tbody td {
    &:first-child {
      padding-left: 16px!important;
      padding-right: 16px!important;
    }

    &:not(:first-child) {
      padding-left: 8px!important;
      padding-right: 8px!important;

      @media (min-width: $desktop-breakpoint) {
        padding-left: 16px!important;
        padding-right: 16px!important;
      }
    }
  }
}
</style>
